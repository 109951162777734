.her2020 {
  .search-view {
    position: fixed;
    top: 0;
    height: 100vh;
    background: white;
    padding: 3rem;
    padding-top: 9rem;
    z-index: 11111;
    overflow: auto;
    -webkit-overflow-scrolling:touch;
    display: none;
    left: 86px;
    height: 100vh;
    width: calc(100vw - 86px);
    //will-change: opacity;

    @media (max-width: 1025px) and (min-height: 900px) {
      left: 72px;
      width: calc(100vw - 72px);
    }


    @media(max-width: 425px) {
      width: 100vw;
      left:0;
      //padding: 15px;
      padding: 1.7rem;
      padding-top: 5rem;
    }

    &.toggled {
      display: block;
    }

    > .inner {
      opacity: 0;
      will-change: opacity;
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #E1E1E1;
    }

    &::-webkit-scrollbar-thumb {
      background: #000000;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }

    .close-search {
      position: fixed;
      top: 0;
      right: 0;
      height: 87px;
      padding-right: 1.7rem;
      display: flex;
      align-items: center;
    }

    .searchResults {
      .inner {
        max-width: 700px;
        margin: 0 auto;

        .article-result {
          margin-bottom: 2rem;
          border-bottom: solid 1px #D8D8D8;
          padding-bottom: 1rem;

          &:hover {
            color: #E2002B !important;
            a {
              color: #E2002B !important;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }

        .title {
          text-transform: uppercase;
          font-size: 2.45rem;
          margin-bottom: 0.4rem;
          display: flex;
        }

        .description {
          font-size: 1.1rem;
          //display: flex;

          p {
            line-height: 2rem;
          }

        }

        span.surlign {
          display: inline-block;
          position: relative;
          color: white;
          z-index: -1;
          padding-left: 0.2rem;
          margin-left: 0.1rem;
          margin-right: 0.1rem;
          padding-right: 0.2rem;
        }

        span.surlign {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            left: 0;
            background: black;
            width: 100%;
            height: 83%;
            transform: translateY(11%);
          }
        }

      }
    }

    .searchBox {
      font-size: 6rem;
      border-bottom: solid 1px black;
      margin-bottom: 3rem;
      line-height: 4rem;
      transition: all 0.2s ease;
      will-change: margin-left, margin-top, margin-right;

      @media (max-width: 425px) {
        line-height: 7rem;
      }

      span.results-number {
        color: #E2002B;
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 2.3px;
        line-height: 1.3rem;
        display: inline-block;
        overflow: hidden;
        opacity: 0;
        transform: translateY(12px);
        transition: all 0.1s ease;

        @media (max-width: 425px) {
          font-size: 2rem;
          line-height: 2rem;
        }

        &.active {
          opacity: 1;
          transform: translateY(0);
          transition: all 0.4s ease 0.15s;
        }

      }

      &.start {
        margin-left: 8rem;
        margin-right: 8rem;
        //margin-top: 19vh;
        margin-top: 11vh;

        @media (max-width: 1025px) and (min-height: 900px) {
          margin-left: 5rem;
          margin-right: 5rem;
        }

      }

      @media(max-width: 425px) {

        &.start {
          padding-top: 0;
          margin-left: 15px;
          margin-right: 15px;
          margin-top: 5vh;
        }

      }

      input {
        text-transform: uppercase;
        width: 100%;
        border: 0;
        outline: none;

        @media(max-width: 425px) {
          padding-left: 0;
          outline: none;
          padding-top: 0;
          padding-bottom: 0;
        }

        @media(max-width: 1024px) {
          font-size: 5rem;
          padding-left: 0;
          padding-bottom: 1rem;
        }

      }

    }

  }
}
