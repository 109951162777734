@import "../../../index.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  font-size: 20px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000000000000000000000000000 !important;
}

q {
  font-style: italic;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

}

p {
  line-height: 1.66rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: 1rem;
}

a {
  color: black;
  text-decoration: none !important;

  &:hover, &:focus {
    text-decoration: none !important;
  }

}

button, button:focus, button:active, button:hover{
  outline: none !important;
  border: none !important;
}

@media (max-width: 1280px) {
  html {
    font-size: 17px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media (min-height: 1000px) and (orientation: portrait){
  html {
    font-size: 20px;
  }
}

@media (max-width: 769px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 425px)
{
  html {
    font-size: 15px;
  }
}

@media (max-width: 425px)
{

  html {
    font-size: 13px;
  }

}

html{
  *[data-text='true'] {
    overflow: hidden;
    &:after {
      transform: scale(1);
      opacity: 1;
      transform-origin: top left;
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1000000000000000 !important;
}

html.wf-active {

  *[data-text='true'] {
    &:after {
      //transform: scaleX(0);
      opacity: 0;
      transform: scale(0);
      transition: opacity .35s ease, transform 0.1s 0.35s;
    }
    opacity: 1;
    transition: opacity 0.35s ease;
  }

  *[data-fade='true'] {
    opacity: 1 !important;
    transition: all 1s ease;
  }

}

.her2020 {
  overflow: hidden;
}


@font-face {
  font-family: 'icomoon-her2020';
  src:  url('./icofonts/icomoon2020.eot');
  src:  url('./icofonts/icomoon2020.eot#iefix') format('embedded-opentype'),
  url('./icofonts/icomoon2020.ttf') format('truetype'),
  url('./icofonts/icomoon2020.woff') format('woff'),
  url('./icofonts/icomoon2020.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-her2020-"], [class*=" ico-her2020-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-her2020' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-her2020-left_arrow:before {
  content: "\e900";
  color: #e2002b;
}
.ico-her2020-right_arrow:before {
  content: "\e901";
  color: #e2002b;
}
.ico-her2020-dropdown_off:before {
  content: "\e902";
  color: #e2002b;
}
.ico-her2020-dropdown_on:before {
  content: "\e903";
  color: #e2002b;
}
.ico-her2020-play_video:before {
  content: "\e904";
  color: #fff;
}
.ico-her2020-question_mark:before {
  content: "\e905";
  color: #fff;
}
.ico-her2020-rs_her:before {
  content: "\e906";
  color: #fff;
}
.ico-her2020-rs_in:before {
  content: "\e907";
  color: #fff;
}
.ico-her2020-rs_twitter:before {
  content: "\e908";
  color: #fff;
}
.ico-her2020-search_arrow:before {
  content: "\e909";
  color: #e2002b;
}
.ico-her2020-search:before {
  content: "\e90a";
  color: #fff;
}
