.her2020 {
  .numero {
    display: flex;
    min-height: 100vh;
  }

  .componentExemple {
    display: flex;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: yellow;
    font-size: 5rem;
  }
}

@import "./numero-media.scss";
@import "./lateral-bar.scss";

@import "../components/style/header.scss";
@import "../components/style/lateral-bar.scss";
@import "../components/style/main.scss";
