.her2020 {
  .component {

    &.component-carrousel {
      width: 100% !important;
      margin-top: 2rem;
      margin-bottom: 2rem;


      .slick-slide {
        //border-radius: 5px;
      }

    }

    &.component-text {

      @media(max-width: 768px) {
        table {
          border-collapse: collapse;
          width: 100% !important;
          display: block;
          tbody {
            width: 100% !important;
            display: block;
          }
          tr {
            width: 100% !important;
            display: inline-grid;
            grid-template-columns: 50% 50%;

            td {
              padding: 1rem;
              width: 100%;
            }

          }
        }
      }

    }



    &.component-chiffres {
      margin-top: 2rem;
      //margin-bottom: 2rem;
      width: 100% !important;
      //background: #E2002B;
      color: white;
      //padding: 2.2rem;
      font-size: 1rem;
      margin-bottom: 1.3rem;

      ul {
        width: 100%;
        li {
          width: 100%;
          border-bottom: solid 1px rgba(0, 0, 0, 0.25);
          padding-bottom: 1rem;
          margin-bottom: 1.2rem;
          font-size: 1rem;

          &:last-child {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }

      .slick-track {
        display: flex;
      }

      .slick-slider {
        //height: 206px;

        @media (max-width: 600px) {
          //height: 129px;
        }

        .slick-list, .slick-track{
          height: 100%;
        }

        .slick-slide {
          display: flex;
          height: auto;
          align-items: center;
          justify-content: center;
          * {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100% !important;
          }
        }

      }

      .slick-slide {
        border-radius: 5px;

        &:not(.slick-active) {
          .content-chiffre {
            height: 85% !important;
            transition: all 0.4s ease;

          }
        }

        &.slick-active {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          .content-chiffre {
            display: flex !important;
            background-color: #E2002B;
            color: white;
            transition: all 0.6s ease;
            border-radius: 0px;

            .chiffre {
              opacity: 1;
              transition: opacity 1s ease;
            }

            .legende {
              opacity: 1;
              transition: opacity 1s ease;
            }

          }
        }

        .content-chiffre {
          background-color: #e4e4e4;
          transition: all 0.2s ease;
          display: flex;
          flex-direction:column;
          justify-content: center;
          align-items:center;
          padding-top: 3.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 3.5rem;

          .legende {
            opacity: 0;
            font-size: 1.2rem;
            width: 100%;
            text-align: center;
            font-size: calc(0.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
            font-size: calc(1rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
          }

          .chiffre {
            opacity: 0;
            line-height: 4.1rem;
            font-weight:bold;
            font-size: calc(2.1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
            font-size: calc(4rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
            margin-bottom: 0.4rem;
          }

          > * { height: auto !important;}

        }
      }
    }

  }

  @import "./components-media.scss";
}
