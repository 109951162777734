/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
@import url(~react-perfect-scrollbar/dist/css/styles.css);
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.her2020 .menu {
  z-index: 1300000000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  border-left: 0 !important;
  background-color: transparent;
  overflow: hidden;
  position: fixed;
  display: none;
  left: 86px;
  height: 100vh;
  width: calc(100vw - 86px); }
  .her2020 .menu.toggled {
    display: block; }
  .her2020 .menu.toggled {
    display: block; }
  .her2020 .menu .footer-menu {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 7vh;
    width: 100%;
    background: white;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    padding-left: calc(48px + 15px);
    padding-right: calc(48px + 15px);
    opacity: 0;
    flex-wrap: wrap;
    justify-content: center; }
    .her2020 .menu .footer-menu .socials {
      display: flex;
      width: 100%;
      margin-bottom: 2rem; }
      .her2020 .menu .footer-menu .socials nav {
        width: 100%; }
        .her2020 .menu .footer-menu .socials nav ul {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: center; }
          .her2020 .menu .footer-menu .socials nav ul li {
            width: auto;
            margin-left: 1.8rem;
            margin-right: 1.8rem !important; }
            .her2020 .menu .footer-menu .socials nav ul li span {
              display: none; }
    @media (max-width: 1025px) and (min-height: 900px) {
      .her2020 .menu .footer-menu {
        padding-left: 54px;
        padding-right: 46px; } }
    .her2020 .menu .footer-menu .inner-footer-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .her2020 .menu .footer-menu .inner-footer-menu .credits {
        color: black;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center; }
        @media (max-width: 1025px) {
          .her2020 .menu .footer-menu .inner-footer-menu .credits {
            font-size: 0.85rem; } }
        .her2020 .menu .footer-menu .inner-footer-menu .credits img.logo-her {
          width: 98px;
          margin-left: 0.7rem; }
        .her2020 .menu .footer-menu .inner-footer-menu .credits .ico-her2020-rs_her:before {
          color: black; }
        .her2020 .menu .footer-menu .inner-footer-menu .credits a {
          color: black;
          font-weight: bold;
          margin-left: 0.2rem; }
          .her2020 .menu .footer-menu .inner-footer-menu .credits a:hover {
            color: black;
            text-decoration: underline !important; }
    .her2020 .menu .footer-menu ul {
      display: flex;
      justify-content: flex-start; }
      .her2020 .menu .footer-menu ul li {
        color: black;
        margin-right: 5rem !important;
        cursor: pointer;
        font-size: 1rem;
        letter-spacing: 0.3px; }
        @media (max-width: 1025px) {
          .her2020 .menu .footer-menu ul li {
            font-size: 0.85rem;
            margin-right: 2rem !important; } }
        body:not(.touch) .her2020 .menu .footer-menu ul li *:hover, body:not(.touch) .her2020 .menu .footer-menu ul li:hover {
          text-decoration: underline; }
        .her2020 .menu .footer-menu ul li a, .her2020 .menu .footer-menu ul li div {
          color: black;
          text-transform: uppercase;
          font-weight: 400; }
          body:not(.touch) .her2020 .menu .footer-menu ul li a:hover, body:not(.touch) .her2020 .menu .footer-menu ul li div:hover {
            text-decoration: underline !important; }

.her2020 .menu-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: transparent;
  opacity: 0;
  display: flex;
  padding-top: 0;
  padding-left: 48px;
  will-change: opacity; }
  .her2020 .menu-inner .chapitre {
    opacity: 0; }
  .her2020 .menu-inner .categories {
    font-size: 1.4rem;
    color: #000;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    background: #E2002B;
    margin-bottom: 0;
    width: 350px;
    min-width: 350px;
    align-items: center;
    justify-content: center;
    height: 205px; }
    @media (max-width: 1025px) and (min-height: 900px) {
      .her2020 .menu-inner .categories {
        width: 210px;
        min-width: 210px; } }
    .her2020 .menu-inner .categories .chapitre-title {
      padding: 1.7rem;
      padding-top: 1.2rem;
      padding-bottom: 0.2rem;
      font-weight: 500;
      color: white;
      line-height: 4.5rem;
      letter-spacing: -1.5px;
      font-size: 5rem;
      text-transform: uppercase;
      display: flex; }
      .her2020 .menu-inner .categories .chapitre-title span {
        text-align: center; }
      @media (min-width: 1280px) {
        .her2020 .menu-inner .categories .chapitre-title {
          line-height: 3.5rem;
          letter-spacing: -1.5px;
          font-size: 4rem; } }
      @media (max-width: 1025px) and (min-height: 900px) {
        .her2020 .menu-inner .categories .chapitre-title {
          line-height: 4rem;
          font-size: 4.9rem; } }
      .her2020 .menu-inner .categories .chapitre-title i.ico-her2020-question_mark {
        font-size: 3.2rem;
        margin-left: 0.5rem; }
    .her2020 .menu-inner .categories .chapitre-wallpaper {
      display: block;
      height: 133px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-color: #dadada;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .her2020 .menu-inner .categories .number {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 9px;
      color: #fff;
      font-weight: bold;
      font-size: 1.4rem;
      margin-right: 26px;
      transform: translate(22%, -23%);
      z-index: 0; }
      .her2020 .menu-inner .categories .number .inner {
        background-color: #FF5F7D;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px; }
      .her2020 .menu-inner .categories .number:after {
        content: "";
        position: absolute;
        top: 0;
        left: 2px;
        width: 20px;
        height: 11px;
        background: #D72D4D;
        clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
        z-index: -1; }
  .her2020 .menu-inner .scrollbar-container {
    flex-basis: calc(100% - 350px);
    margin-left: 15px;
    margin-right: 15px; }
    @media (max-width: 1025px) and (min-height: 900px) {
      .her2020 .menu-inner .scrollbar-container {
        flex-basis: calc(100% - 210px); } }
    .her2020 .menu-inner .scrollbar-container .ps__thumb-x {
      border-radius: 0;
      background-color: #e2002b !important;
      opacity: 1 !important; }
    .her2020 .menu-inner .scrollbar-container .ps__rail-x {
      transform: translateY(2px);
      opacity: 1 !important; }
    .her2020 .menu-inner .scrollbar-container .ps__thumb-y {
      background-color: #f0f0f0; }
  .her2020 .menu-inner .articles-outer {
    padding: 1.7rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    background: white;
    flex-basis: calc(100% - 350px);
    margin-left: 15px;
    margin-right: 15px;
    margin-right: 0;
    padding-right: 0; }
    @media (max-width: 425px) {
      .her2020 .menu-inner .articles-outer {
        margin-left: 0px; } }
    .her2020 .menu-inner .articles-outer .flickity-viewport {
      width: 100%; }
      .her2020 .menu-inner .articles-outer .flickity-viewport .flickity-slider {
        width: 100%; }
    .her2020 .menu-inner .articles-outer button.flickity-button.flickity-prev-next-button.previous {
      top: calc(50% - 0.9rem); }
      @media (max-width: 425px) {
        .her2020 .menu-inner .articles-outer button.flickity-button.flickity-prev-next-button.previous {
          top: calc(50% - 2.9rem); } }
    .her2020 .menu-inner .articles-outer .flickity-prev-next-button {
      border-radius: 0; }
    .her2020 .menu-inner .articles-outer button.flickity-button.flickity-prev-next-button.next {
      top: calc(50% - 0.9rem); }
      @media (max-width: 425px) {
        .her2020 .menu-inner .articles-outer button.flickity-button.flickity-prev-next-button.next {
          top: calc(50% - 2.9rem); } }
    .her2020 .menu-inner .articles-outer .flickity-prev-next-button[disabled] {
      display: none; }
    .her2020 .menu-inner .articles-outer::-webkit-scrollbar {
      width: 7px;
      display: none; }
    .her2020 .menu-inner .articles-outer::-webkit-scrollbar-track {
      background: #E1E1E1;
      display: none; }
    .her2020 .menu-inner .articles-outer::-webkit-scrollbar-thumb {
      background: #000000;
      display: none; }
    .her2020 .menu-inner .articles-outer::-webkit-scrollbar-thumb:hover {
      background: #000000;
      display: none; }
    .her2020 .menu-inner .articles-outer li {
      cursor: pointer;
      padding-right: 15px;
      margin-bottom: 7px; }
      @media (max-width: 1024px) {
        .her2020 .menu-inner .articles-outer li {
          margin-bottom: 16px; } }
      .her2020 .menu-inner .articles-outer li:last-child {
        padding-right: 0; }
      .her2020 .menu-inner .articles-outer li a {
        font-size: 0.92rem;
        width: 100%; }
        .her2020 .menu-inner .articles-outer li a > div {
          width: 100%;
          height: 205px;
          width: 350px; }
          @media (max-width: 1025px) and (min-height: 900px) {
            .her2020 .menu-inner .articles-outer li a > div {
              width: 279px; } }
          .her2020 .menu-inner .articles-outer li a > div.wallpaper {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-color: #dadada;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
          .her2020 .menu-inner .articles-outer li a > div .title-outer {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.75);
            color: white;
            visibility: hidden;
            opacity: 0; }
            .her2020 .menu-inner .articles-outer li a > div .title-outer .title {
              transform: translateY(30px);
              opacity: 0; }
            .her2020 .menu-inner .articles-outer li a > div .title-outer span {
              color: white;
              padding-left: 3rem;
              padding-right: 3rem;
              font-size: 2.4rem;
              text-transform: uppercase;
              text-align: center;
              line-height: 2.4rem;
              margin-bottom: 0; }
        .her2020 .menu-inner .articles-outer li a span {
          color: black; }
          .her2020 .menu-inner .articles-outer li a span.who {
            text-transform: uppercase;
            margin-top: 0.4rem;
            display: block;
            width: 350px;
            font-size: 1rem;
            line-height: 1.1rem; }
            @media (max-width: 1025px) and (min-height: 900px) {
              .her2020 .menu-inner .articles-outer li a span.who {
                width: 279px;
                font-size: 1.4rem;
                line-height: 1.4rem; } }
            .her2020 .menu-inner .articles-outer li a span.who span.name {
              font-weight: 600; }
      body:not(.touch) .her2020 .menu-inner .articles-outer li:hover a .title-outer {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.6s ease; }
        body:not(.touch) .her2020 .menu-inner .articles-outer li:hover a .title-outer .title {
          transform: translateY(0px);
          transition: all 0.4s ease 0.1s;
          opacity: 1; }
      .her2020 .menu-inner .articles-outer li:last-child {
        border: 0; }
  .her2020 .menu-inner .articles-test {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 5rem;
    padding-top: 3rem;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; }
    .her2020 .menu-inner .articles-test::-webkit-scrollbar {
      width: 8px; }
    .her2020 .menu-inner .articles-test::-webkit-scrollbar-track {
      background: #E1E1E1; }
    .her2020 .menu-inner .articles-test::-webkit-scrollbar-thumb {
      background: #000000; }
    .her2020 .menu-inner .articles-test::-webkit-scrollbar-thumb:hover {
      background: #000000; }
    .her2020 .menu-inner .articles-test .chapitre-article {
      background: #F0F0F0;
      margin-left: 15px;
      padding: 0 !important;
      display: flex;
      margin-bottom: 2rem; }
      .her2020 .menu-inner .articles-test .chapitre-article:hover {
        background: #eae8e8; }
      @media (max-width: 1025px) and (min-height: 900px) {
        .her2020 .menu-inner .articles-test .chapitre-article {
          margin-left: 4px; } }
      .her2020 .menu-inner .articles-test .chapitre-article.chiffre-cle {
        margin-top: 2rem;
        margin-bottom: 2rem !important; }
      .her2020 .menu-inner .articles-test .chapitre-article a {
        width: 100%;
        height: 100%;
        display: flex; }
        .her2020 .menu-inner .articles-test .chapitre-article a:hover {
          color: black; }
      .her2020 .menu-inner .articles-test .chapitre-article .wallpaper {
        height: 205px;
        width: 365px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: #dadada;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media (max-width: 1025px) and (min-height: 900px) {
          .her2020 .menu-inner .articles-test .chapitre-article .wallpaper {
            width: 210px; } }
      .her2020 .menu-inner .articles-test .chapitre-article .titles {
        display: flex;
        flex-direction: column;
        padding-left: 2.4rem;
        justify-content: center; }
        @media (max-width: 1025px) and (min-height: 900px) {
          .her2020 .menu-inner .articles-test .chapitre-article .titles {
            flex: 1; } }
        .her2020 .menu-inner .articles-test .chapitre-article .titles .category {
          font-size: 1.3rem;
          color: #E2002B;
          text-transform: uppercase;
          letter-spacing: 6px;
          margin-bottom: 0.4rem; }
          @media (max-width: 1025px) {
            .her2020 .menu-inner .articles-test .chapitre-article .titles .category {
              font-size: 1.3rem;
              letter-spacing: 1px; } }
        .her2020 .menu-inner .articles-test .chapitre-article .titles .article-title {
          font-size: 4rem;
          text-transform: uppercase;
          line-height: 4rem;
          letter-spacing: -1px; }
          @media (max-width: 1025px) {
            .her2020 .menu-inner .articles-test .chapitre-article .titles .article-title {
              font-size: 3.7rem;
              line-height: 3.2rem;
              letter-spacing: -1px; } }
    .her2020 .menu-inner .articles-test .title {
      padding-left: 15px;
      text-transform: uppercase;
      margin-bottom: 2rem;
      display: flex; }
      .her2020 .menu-inner .articles-test .title span.sommaire {
        color: #E2002B;
        margin-right: 0.75rem;
        font-size: 1.8rem;
        transform: translateY(-6px); }
      .her2020 .menu-inner .articles-test .title .sommaire-title {
        font-size: 2.5rem;
        line-height: 2.5rem; }
    .her2020 .menu-inner .articles-test > ul {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 2rem; }
      .her2020 .menu-inner .articles-test > ul > li {
        padding: 15px;
        padding-top: 2px;
        padding-right: 0;
        width: 100%;
        opacity: 0;
        will-change: opacity, transform; }
        .her2020 .menu-inner .articles-test > ul > li div.inner {
          width: 100%;
          position: relative;
          display: flex; }

.her2020 .background-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0;
  will-change: opacity; }

.her2020 { }

@media (min-height: 900px) and (max-width: 1025px) {
  .her2020 .menu {
    left: 72px;
    width: calc(100vw - 72px); }
    .her2020 .menu .footer-menu ul li {
      margin-right: 1rem !important; }
  .her2020 .menu-inner .articles-test > ul > li {
    padding: 5px;
    padding-right: 0; } }

@media (max-width: 425px) {
  .her2020 .menu {
    width: calc(100vw);
    left: 0;
    overflow: auto;
    height: 100vh !important;
    background: white;
    z-index: 130000;
    -webkit-overflow-scrolling: touch; }
    .her2020 .menu::-webkit-scrollbar {
      width: 8px; }
    .her2020 .menu::-webkit-scrollbar-track {
      background: #E1E1E1; }
    .her2020 .menu::-webkit-scrollbar-thumb {
      background: #000000; }
    .her2020 .menu::-webkit-scrollbar-thumb:hover {
      background: #000000; }
    .her2020 .menu .footer-menu {
      position: relative;
      padding-left: 0;
      padding-top: 2rem;
      padding-right: 0;
      height: auto !important;
      padding-bottom: 5rem; }
      .her2020 .menu .footer-menu .inner-footer-menu {
        max-width: 100%;
        transform: translateX(0);
        flex-direction: column; }
        .her2020 .menu .footer-menu .inner-footer-menu .credits {
          margin-top: 1.5rem; }
      .her2020 .menu .footer-menu ul {
        flex-direction: column;
        text-align: center; }
        .her2020 .menu .footer-menu ul li {
          margin-right: 0 !important;
          font-size: 1.7rem;
          margin-bottom: 0.7rem; }
    .her2020 .menu .menu-inner {
      padding-top: 0px;
      padding-left: 6px;
      flex-direction: column;
      position: relative;
      height: auto; }
      .her2020 .menu .menu-inner .categories {
        width: 77%;
        height: auto;
        min-width: initial; }
        .her2020 .menu .menu-inner .categories .inner {
          width: 100%; }
        .her2020 .menu .menu-inner .categories .chapitre-title {
          /*font-size: 6rem;
            line-height: 6rem;*/
          font-size: 4.5rem;
          line-height: 4.8rem;
          padding-bottom: 1rem; }
          .her2020 .menu .menu-inner .categories .chapitre-title span {
            text-align: left !important; }
          .her2020 .menu .menu-inner .categories .chapitre-title i.ico-her2020-question_mark {
            font-size: 4rem;
            line-height: 5rem;
            margin-left: 0.7rem;
            transform: translateY(3px); }
      .her2020 .menu .menu-inner .menu-numero {
        padding-left: 15px;
        padding-right: 15px;
        position: relative; }
        .her2020 .menu .menu-inner .menu-numero i.icon-her2020-original-chevron_down {
          color: white;
          position: absolute;
          top: 50%;
          transform: translateY(calc(-50% - 2px));
          right: 1.5rem;
          font-size: 1.7rem; }
        .her2020 .menu .menu-inner .menu-numero .numeros-menu-outer {
          position: relative;
          background: rgba(66, 62, 222, 0.690196);
          padding: 12px;
          z-index: 1;
          width: 99%;
          margin-top: 1rem;
          bottom: 0;
          left: 0;
          /* -webkit-transform: translateY(100%); */
          -ms-transform: translateY(100%);
          transform: translateY(0%);
          padding-top: 0;
          padding-bottom: 0 !important; }
          .her2020 .menu .menu-inner .menu-numero .numeros-menu-outer select {
            color: white;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            width: 100%;
            background: transparent;
            font-size: 1.2rem;
            padding-left: 0.7rem;
            border: 0 !important;
            outline: none; }
          .her2020 .menu .menu-inner .menu-numero .numeros-menu-outer .numeros-menu li {
            margin-bottom: 2.2em;
            margin-top: 1rem; }
            .her2020 .menu .menu-inner .menu-numero .numeros-menu-outer .numeros-menu li a {
              font-size: 1.05rem;
              color: white; }
        .her2020 .menu .menu-inner .menu-numero .menu-numeros-btn {
          font-size: 0.9rem; }
      .her2020 .menu .menu-inner .articles-test {
        margin-top: 3.4rem;
        padding-bottom: 0;
        overflow-y: inherit;
        overflow-x: inherit;
        -webkit-overflow-scrolling: auto; }
        .her2020 .menu .menu-inner .articles-test .title {
          margin-bottom: 1rem; }
          .her2020 .menu .menu-inner .articles-test .title .sommaire {
            font-size: 4.8rem;
            line-height: 4.8rem; }
          .her2020 .menu .menu-inner .articles-test .title .sommaire-title {
            display: none; }
        .her2020 .menu .menu-inner .articles-test::-webkit-scrollbar {
          width: 0;
          display: none; }
        .her2020 .menu .menu-inner .articles-test .chapitre-article .titles {
          padding-left: 15px;
          padding-bottom: 15px;
          padding-top: 15px;
          padding-right: 15px; }
          .her2020 .menu .menu-inner .articles-test .chapitre-article .titles .category {
            font-size: 2.2rem;
            color: #E2002B;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 2.3rem;
            margin-bottom: 0.4rem; }
        .her2020 .menu .menu-inner .articles-test .chapitre-article a {
          flex-direction: column; }
          .her2020 .menu .menu-inner .articles-test .chapitre-article a .wallpaper {
            width: 100%; }
        .her2020 .menu .menu-inner .articles-test .edito, .her2020 .menu .menu-inner .articles-test .chiffre-cle {
          padding-left: 0;
          margin-left: 0; }
        .her2020 .menu .menu-inner .articles-test > ul {
          padding-bottom: 3rem;
          padding-left: 15px;
          padding-right: 15px; }
          .her2020 .menu .menu-inner .articles-test > ul > li {
            padding-left: 0;
            padding-right: 0; }
            .her2020 .menu .menu-inner .articles-test > ul > li div.inner {
              flex-direction: column; }
              .her2020 .menu .menu-inner .articles-test > ul > li div.inner .scrollbar-container {
                margin-left: 0;
                margin-right: 0; }
      .her2020 .menu .menu-inner .articles-test::-webkit-scrollbar {
        width: 5px; }
      .her2020 .menu .menu-inner .articles {
        height: auto;
        padding-bottom: 1rem;
        padding-top: 0;
        padding: 0;
        margin-top: 1rem; }
        .her2020 .menu .menu-inner .articles li {
          width: 100%;
          padding-right: 0;
          margin-right: 15px;
          flex: 0 0 auto; }
          .her2020 .menu .menu-inner .articles li span.who {
            font-size: 2rem;
            line-height: 2.2rem;
            margin-top: 0.9rem;
            width: 100%;
            margin-bottom: 0.4rem; }
          .her2020 .menu .menu-inner .articles li .wallpaper {
            width: 100%; }
        .her2020 .menu .menu-inner .articles a {
          font-size: 1.2rem; }
      .her2020 .menu .menu-inner .articles-test > ul > li {
        flex: 0 1 100%; } }
