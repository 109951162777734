@import "../../mixins.scss";

.her2020 {
  .couverture .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7%;
    color: white;
    z-index: -1;

  }

  .couverture {
    h1 {
      font-size: calc(1.7vw + 1vh + 1vmin);
      position: relative;

      a {
        &:after {
          @include wait(#c6c5c5);
        }
      }

    }
  }

  .couverture {
    .frame .title {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 2rem;
      padding-top: 1rem;
      z-index: 3;
      img {
        //max-width: 450px;
        max-width: 50%;
        //width: 100%;
      }

      a {
        color: white;

        &:hover {
          text-decoration: none;
        }

      }

    }
  }

  .couverture {

    .frame {

      a {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.read-more {
          position: relative;
          &:after {
            @include wait(#bab9b9);
          }
        }

        //&:hover {
        //
        //  .fx-img {
        //    background: rgba(0, 0, 0, 0.39);
        //    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 27%);
        //    transition: all 0.3s ease-out;
        //  }
        //
        //  p {
        //    opacity: 1;
        //    transform: translateY(0px);
        //    height: auto;
        //    //transition: all 0.4s ease-out;
        //    transition: all 500ms cubic-bezier(0.000, 0.000, 0.000, 1.065);
        //
        //  }
        //
        //  h2 {
        //    font-size: 2.85rem;
        //    line-height: 3.2rem;
        //    transition: all 0.4s ease;
        //    transform: scale(0.87);
        //    transform-origin: top left;
        //  }
        //
        //}

      }

      p {
        display: flex;
        height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        transform: translateY(20px);
        opacity: 0;
        width: 70%;
        cursor: pointer;
      }

    }

  }

  .article-couverture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background: #b0b0b0;
    background: #cb0527;

    .video-outer-p {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.8509803921568627);
      z-index: 0;
      visibility: hidden;
      opacity: 0;

      @media(max-width: 768px) {
        background: rgba(0, 0, 0, 0.93);
      }

      &.active {
        z-index: 100;
        visibility: visible;
        opacity: 1;
      }

      > div {
        width: 100%;
        height: 100%;


        .video-outer-player {
          height: 100%;

          @media(max-width: 425px) {
            display: flex;
            align-items: center;
          }

          video.video-container {
            width: 100%;
            height: 100%;

            @media(max-width: 425px) {
              height: 67%;
            }

          }

        }

      }

      &.play {
        z-index: 1;
        visibility: visible;
        opacity: 1;
        transition: all 1.5s ease;
      }

    }

    .inner-outer {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .video-outer-player {
        position: absolute;
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
      }

      .inner-center {
        position: relative;
        margin: 0 auto;
        max-width: 573px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;

        @media(max-width: 425px) {
          width: 72%;
        }

        .video-outer {
          padding: 3.5rem;
          padding-top: 3rem;
          padding-left: 5.5rem;
          padding-bottom: 2.8rem;
          background: black;

          button#btn-play-video {
            background: transparent;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-77px);
            left: 0;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              transform: translate(-50%,-77px) scale(1.2);
              transform-origin: center;
              transition: all 0.3s ease;
            }

          }

          .text {
            color: white;
            font-size: 2.3rem;
            line-height: 2.6rem;
            font-weight: 800;
          }

          .subtitle-video {
            color: #51D0EE;
            text-transform: uppercase;
            margin-top: 0.7rem;
            display: block;
            letter-spacing: 0.1rem;
          }

        }

      }

    }

    .outer-i {
      display: flex;
      //&:after {
      //  @include wait(#b8b8b8);
      //}

      .start-video {
        text-transform: uppercase;
        color: white;
        //padding-right: 0.85rem;
        //border-right: solid 2px rgba(151, 151, 151, 0.23);
        //margin-right: 0.85rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          margin-right: 0.35rem;
        }

      }

    }

    .time {
      margin-right: 1.2rem;
    }

    .time, .status {
      //margin-top: 1em;
      margin-top: 0.5em;
      font-size: 1rem;
      font-weight: 400;
      position: relative;
      display: flex;
      color: white;
      align-items: center;

      i {
        color: white;
        margin-right: 0.35rem;
      }

    }

    .chapitre {
      font-weight: 300;
      text-transform: uppercase;
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
      position: relative;
      letter-spacing: 4px;
      font-size: 0.85rem;

      &:after {
        @include wait(#cfcfcf);
      }

      .number {
        font-weight: 600;
        font-size: 1.2rem;
        margin-right: 0.5em;
        background: #F03D00;
        padding: 0.2rem;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .titles {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: white;
      }

      .subtitle {
        text-transform: uppercase;
        font-size: 1.5rem;
        letter-spacing: 5px;
        margin-bottom: 0.8rem;
        position: relative;

        &:after {
          @include wait(#d4062e);
        }

        @media(max-width: 425px) {
          text-transform: uppercase;
          font-size: 1.5rem;
          letter-spacing: 2px;
          margin-bottom: 0.8rem;
          width: 60%;
          line-height: 1.8rem;
        }

        @media(max-width: 320px) {
          width: 69%;
        }

      }

    }

    h1, h2 {
      //font-size: 3.09rem;
      //line-height: 3.80rem;
      font-size: 5.8rem;
      //line-height: 4.8rem;
      line-height: 5.8rem;
      color: white;
      text-transform: uppercase;
      font-weight: 400;
      width: 100%;
      transition: all 0.2s ease;
      //cursor: pointer;
      transform-origin: top left;
      position: relative;

      @media (max-width: 425px) {
        font-size: 4.1rem;
        line-height: 3.8rem;
      }

      @media( max-width: 320px) {
        font-size: 3rem;
        line-height: 3rem;
      }

      &:after {
        //@include wait(#d30129);
        @include wait(#d4062e);
      }

    }


    > .inner {
      position: absolute;
      display: flex;
      bottom: 0;
      //width: calc(100% / 3 * 2);
      //width: calc(80%);
      width: calc(88%);
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: white;
      z-index: 3;
      padding: 7%;
      padding-right: 0%;
    }

  }

  .article-view {

    .article-couverture {
      > .inner {
        //padding: 5%;
        padding: 3.7rem;
      }
    }

    .outer-i {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      background: black;
      padding: 1.2rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      z-index: 11;

      .time {
        margin-right: 0;
        padding-right: 0.85rem;
        border-right: solid 2px rgba(151, 151, 151, 0.23);
      }

      .status {
        padding-left: 0.85rem;
      }

      .time, .status {
        margin-top: 0;
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;

      i {
          color: #e2002b;

          &.icon-her2020-original-status_done {
            color: #02a069;
          }

        }

      }

    }

  }

  .chapitres {
    height: 22vh;
    display: flex;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .chapitre {
      display: flex;
      width: calc(100% / 3);
      height: 100%;
      border-right: solid 1px #D8D8D8;

      &:hover {
        background: #2B3DCC;
        background: #f9f8f8;

        /*.title, .chapitre-titre, .time {
          color: white;
        }*/

      }

      &:last-of-type {
        border: 0;
      }

      a {
        display: flex;
        flex-direction: column;
        color: black;
        padding: 7%;
        padding-right: 10%;
        //padding: 9%;
        padding-bottom: 0px;
        width: 100%;
        align-items: flex-start;

        &:hover {
          text-decoration: none;
        }

        .chapitre-titre {
          text-transform: uppercase;
          //font-size: 0.76rem;
          font-size: 0.70rem;
          color: #f95e7d;
          margin-bottom: 0.2rem;
          letter-spacing: 2px;
          display: flex;
          align-items: center;
          height: 24px;
          position: relative;

          &:after {
            @include wait(#eeeded)
          }

          .number {
            color: #fff;
            background: #f95e7d;
            padding: 0.2rem;
            padding-top: 7px;
            font-weight: 700;
            font-size: 14px;
            border-radius: 5px;
            margin-right: 10px;
            width: 23px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .title {
          font-size: 1.2em;
          height: 2.48em;
          margin-top: 0.2rem;
          line-height: 1.6rem;
          margin-bottom: 0.5rem;
          position: relative;
          font-weight: bold;

          &:after {
            @include wait(#ececec)
          }

        }

        .outer-i {
          display: flex;
        }

        .time {
          margin-right: 0.8rem;
        }

        .time, .status {
          //margin-top: 1em;
          margin-top: 0.5em;
          font-size: 1rem;
          font-weight: 400;
          position: relative;
          display: flex;
          align-items: center;

          i {
            color: #2B3DCC;
            margin-right: 0.35rem;

            &.icon-her2020-original-status_done {
              color: #5fca9a;
            }

          }

          &:after {
            @include wait(#ececec)
          }

        }

      }

    }

  }

  .start-btn {
    //position: absolute;
    //bottom: 0;
    //right: 0;
    //width: calc(100% / 3);
    //z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;

    body:not(.touch) & {
      &:hover {
        &:before {
          width: 100%;
        }
        div {
          color: #e2002b;
        }
        i{
          &:before {
            color: #e2002b !important;
          }
        }
      }
    }


    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      transition: all 200ms linear;
      z-index: -1;
      background: white;
    }

    > div {
      width: 100%;
      box-sizing: border-box;
      background: transparent;
      margin-top: 1px;
      display: flex;
      border: solid 1px white;
      padding: 1.2rem;
      text-transform: uppercase;
      padding-top: 0.7rem;
      padding-bottom: 0.5rem;
      border-radius: 0;
      color: white;
      justify-content: center !important;
      align-items: center !important;
      font-size: 1.5rem;
      letter-spacing: 1.2px;

      @media (max-width: 425px) {
        font-size: 2rem;
      }

      .inner-start {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin-left: 0.7rem;
          transform: translateY(-1px);
          font-size: 0.75rem;
          &:before {
            color: white;
          }
        }

      }
      //
      //&:hover {
      //  color: white;
      //  background: #fe4d6e;
      //}
    }

    &:after {
      @include wait(#c10025);
    }

  }
}

@import "./couverture-media.scss";
