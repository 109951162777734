@import "../../mixins.scss";
@import "./slider.scss";
@import "./slider-media.scss";

.her2020 {
  .svg-inline--fa {
    cursor: pointer;
  }

  .article-block {
    &.initial-render {
      opacity: 1;
    }
    opacity: 0;
  }

  .couverture .magazine-title, .article .magazine-title {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding-left: 2em !important;
    padding-top: 1em !important;
    z-index: 2 !important;
    //transition: all 0.35s ease-out !important;

    a {
      color: white;
      text-decoration: none;
    }

    img {
      width: 247px;
    }

    h1 {
      font-size: 1.61em;

      a {
        position: relative;
        &:after {
          @include wait(#C6C5C7)
        }
      }

    }

  }

  .article .magazine-title {
    position: fixed !important;
    //margin-left: 120px;
    margin-left: 87px;
    //margin-top: 9px;
    //margin-top: 13px;
    margin-top: 7px;
    //z-index: 3 !important;
    //z-index: 101 !important;
    z-index: 1010000 !important;
  }

  @keyframes playerLoad {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .player-module {

    .player {
      opacity: 1;
      transform: translateY(0%);
      transition: all 0.4s ease-out;
    }

    //&.active {
    //
    //  .player {
    //    opacity: 1;
    //    transform: translateY(0%);
    //    transition: all 0.4s ease-out;
    //  }
    //
    //}

  }

  .player-outer {
    position: fixed;
    display: flex;
    left: 87px;
    width: calc(100% - 87px);
    //height: 60px;
    bottom: 0px;
    z-index: 10000;

    .player-left {
      display: none;
    }

    .player-right {
      display: none;
    }

  }

  .player {
    display: flex;
    //height: 60px;
    background: white;
    flex: 1 1;
    min-width: 400px;
    //max-width: 848px;
    justify-content: space-between;

    /* Rectangle 5: */
    //box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    //box-shadow: 0 8px 20px 0 rgba(0,35,144,0.16);
    box-shadow: 0 -5px 15px 0 rgba(0,0,0,0.10);
    position: relative;
    box-sizing: border-box;
    //padding-top: 0.7rem;
    //padding-bottom: 0.7rem;
    border-radius: 8px;
    //overflow: hidden;
    //animation: playerLoad 0.6s ease-out forwards;

    .timeline {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;

      .article-outer {
        height: 59px;
        box-shadow: 0 -5px 15px 0 rgba(0,0,0,0.10);
      }

    }

    .timeline-component {
      height: 0;
      background: transparent;
      display: flex;
      position: relative;
      transition: all 0.2s ease;
      overflow: hidden;

      &.active {
        height: 68px;
        transition: all 0.3s ease;
      }

      .hoveringArticle {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 6px;
        background: black;
        color: white;
        z-index: 10;
        //height: 2px;
        //width: 2px;
        //background: red;
        //overflow: hidden;

        &:after {
          position: absolute;
          content: "";
          left: 50%;
          bottom: 0;
          width: 26px;
          height: 26px;
          transform: rotate(-45deg) translate(-50%, -10%);
          background: black;
          border-radius: 4px;z-index: -1;
        }

        span {
          font-size: 0.75rem;
        }

        span.number {
          font-weight: 800;
          font-size: 0.7rem;
          margin-right: 0.8em;
          line-height: 0.7rem;
          padding-left: 4px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-right: 4px;
          border-radius: 2px;
          min-width: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FF5F7D;
          color: white;
        }
      }

    }

    .timeline-inner {
      height: 100%;
      width: 33%;
      //border-radius: 6px;
      overflow: hidden;
      background: #E2002B;
      position: absolute;
      transition: all 0.6s ease-out;
    }

    .timeline-outer {
      height: 100%;
      //border-radius: 6px;
      overflow: hidden;
      background: #A30221;
      position: absolute;
      display: flex;
      width: 100%;
    }

    .category-timeline {
      position: relative;
      border-left: solid 2px rgba(0, 0, 0, 0.19);
      flex: 1;

      @media (max-width: 1024px) {
        flex: auto;
      }

      @media (max-width: 1025px) and (min-height: 900px) {
        flex: auto;
      }

      .category-title {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.9rem;
        text-transform: uppercase;
        color: white;

        @media (max-width: 1025px) and (min-height: 900px) {
          font-size: 1.55rem;
        }

      }

    }

    .chapitre-point {
      width: 17px;
      height: 19px;
      transform: translateY(-50%) translateX(50%);
      top: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
      z-index: 1;

      &:hover {
        cursor: pointer;

        div {
          //width: 14px;
          //height: 14px;
          border: solid 3px #FF5F7D !important;
          box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.4);
        }

      }

      div {
        position: absolute;
        transform: translate(-50%, -10%);
        left: 50%;
        top: 50%;
        //width: 10px;
        //height: 10px;
        //border: solid 3px #DCDBDD;
        //width: 14px;
        //height: 14px;
        width: 15px;
        height: 15px;
        border: solid 3px #DCDBDD;
        background: white;
        border-radius: 100%;

        &.passed {
          border: solid 3px #4E49FC;
        }

        &.current {
          //width: 14px;
          //height: 14px;
          //border: solid 3px #4E49FC;
        }

      }

    }

    .articles-in-category {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      height: 100%;
      z-index: 0;
      top: 0;

      .article {
        transform: translateY(0);
        //width: 1px;
        height: 100%;
        background: white;
        display: flex;
        //border-radius: 100%;
        flex: 1;
        top: 0;
        z-index: 1;
        border: 0;
        background: rgba(0, 0, 0, 0.1);

        &.passed {
          border: 0;
          background: rgba(0, 0, 0, 0.12);
        }

        &.current {
          //border: solid 3px #4e4afb;
          //background: #4e4afb;
          border: 0;
          background: rgba(0, 0, 0, 0.12);
        }

      }

    }

    .chapitre-bullet {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 6px;
      background: black;
      color: white;
      transform: translateY(calc(-100% - 1.3rem));
      z-index: 10;

      &:after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: 0;
        width: 26px;
        height: 26px;
        transform: rotate(-45deg) translate(-50%, -10%);
        background: black;
        border-radius: 4px;z-index: -1;
      }

      span {
        font-size: 0.75rem;
      }

      span.number {
        font-weight: 800;
        font-size: 0.7rem;
        margin-right: 0.8em;
        line-height: 0.7rem;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 4px;
        min-width: 19px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FF5F7D;
        color: white;
      }

    }

    .chapitre-title {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //font-size: 1.23rem;
      font-size: 1.09rem;
      //padding-bottom: 1rem;

      & > div {
        display: flex;
        align-items: center;
      }

      #see-more {
        border: none;
        outline: none;
        margin-left: 0.5rem;
        transform: rotate(180deg) translateY(-1px);
        background: transparent;
        padding: 2px;
      }

      span.number {
        font-weight: 800;
        font-size: 0.7rem;
        margin-right: 1.2em;
        line-height: 0.7rem;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 4px;
        border-radius: 2px;
        min-width: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FF5F7D;
        color: white;
      }

      .author {
        font-weight: bold;
        text-transform: uppercase;
      }

      span.title {
        line-height: 0.7rem;
        font-weight: 400;
        text-transform: uppercase;

        @media (max-width: 320px) {
          //font-size: 1rem !important;
        }

      }

    }

    .btn {
      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      padding: 0rem;
      background: transparent;
      height: 59px;
      width: 54px;
      border-radius: 0;
      font-size: 1.3rem;
      border:0;
      transition: all 0.2s ease-out;

      &:hover {
        cursor: pointer;
        transition: all 0.5s ease-out;

        &.prev {

          span {
            display: block;
            opacity: 1;
            transform: translateX(0%);
          }

        }

        &.prev {

          span {
            display: block;
            opacity: 1;
            transform: translateX(0%);
          }

        }

      }

      a {
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;outline:none;

        &:hover {
          span {
            display: block;
          }
        }

        span {
          display: none;
          font-size: 0.8rem;
        }

      }

      &.prev {
        left: 0;
        border-right: solid 1px #D5D5D5;

        i {
          color: #E2002B;
          font-size: 0.7rem;
          transform: translateX(0);
          transition: all 0.3s ease;
        }

        body:not(.touch) & {
          &:hover {
            i {
              transform: translateX(-3px);
            }
          }
        }

        span {
          display: block;
          opacity: 0;
          padding-right: 1rem;
          transform: translateX(-40%);
        }

      }

      &.next {
        right: 0;
        border-left: solid 1px #D5D5D5;

        a {
          flex-direction: row-reverse;
        }


        i {
          color: #E2002B;
          font-size: 0.7rem;
          transform: translateX(0);
          transition: all 0.3s ease;
        }

        body:not(.touch) & {
          &:hover {
            i {
              transform: translateX(3px);
            }
          }
        }

        span {
          display: block;
          opacity: 0;
          padding-left: 1rem;
          transform: translateX(40%);
        }

      }

    }

  }

  .article {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: translateY(-200px);
    margin-bottom: -80px;

    &.scrolled {
      .magazine-title a {
        color: black;
      }
    }

    figure {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        //width: 100%;
        //height: auto;
        width: 100%;
        //height: 400px;
        object-fit: cover;
        background: #f1f1f1;
        height: auto;
      }

      figcaption {
        margin-top: 0.8rem;
        color: #666666;
        font-size: 0.94rem;
      }

    }

    header {
      width: 100%;
    }

    .sidebar {
      width: 300px;
    }

    .block-chapitre {
      //width: 272px;

      .inner-chapitre {
        //width: 272px;
        padding: 2.5rem;
        padding-top: 2.5rem;
        padding-right: 2.5rem;
        //display: none;

        header {
          background: white;
        }

        a {
          color: white;
          &:hover, &:active {
            color: white;
          }
        }

        .chapitre-name {
          background: #E2002B;
          padding: 1rem;
          display: flex;
          justify-content: center;
          text-transform: uppercase;
          position: relative;

          @media(min-width: 1280px) {
            padding: 1.2rem;
          }

          &:after {
            @include wait(#d4d4d4);
          }

          span {
            font-size: 3.4rem;
            line-height: 3.4rem;

            @media(min-width: 1280px) {
              font-size: 2.6rem;
              line-height: 2.6rem;
            }

            @media (max-height: 850px) and (min-width: 1024px) {
              font-size: 2.3rem !important;
              line-height: 2.3rem  !important;
            }
          }

          i.ico-her2020-question_mark {
            font-size: 1.8rem;
            margin-left: 0.5rem;
            line-height: 1.8rem;
            transform: translateY(0.4rem);
          }

        }

      }

      .inner-chapitre.fixed {
        position: fixed;
        top: 84px;
        width: 272px;
      }

      ul {
        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.7rem;
          padding-bottom: 0.7rem;
          border-bottom: solid 1px #D8D8D8;
          position: relative;
          padding-bottom: 1.1rem;

          &:after {
            @include wait(#f3f0f0);
            height: 83% !important;
          }

          &:hover {
            cursor: pointer;
          }

          .author {
            font-size: 0.95rem;
            line-height: 1.7rem;
            margin-top: 0.15rem;
            margin-bottom: 0.1rem;
          }

          span.author-work {
            font-weight: 100;
            font-size: 0.95rem;
            color: black !important;
          }

          &.current a {
            color: black;
          }

          a {
            color: #cacaca;
            font-weight: 600;
            font-size: 0.76rem;
            display: flex;
            flex-direction: column;

            &:hover {
              font-weight: 600;
            }
          }

        }
      }

      h1 {
        color: #e2002b;
        font-size: 1.6rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        font-weight: 300;
        padding-bottom: 1rem;
        //margin-bottom: 1rem;
        margin-bottom: 0.4rem;
        //border-bottom: solid 2px rgba(0, 0, 0, 0.27058823529411763);
        position: relative;
        margin-top: 1.4rem;

        &:after {
          @include wait(#eaeaea);
          height: 83% !important;
        }

      }

    }

    .article-title {
      font-size: 1rem;
      margin-top: 1rem;
      position: relative;

      &:after {
        @include wait(#bcbcbc)
      }

    }

    .inner-main {
      font-size: 1.1em;
      //max-width: 900px;
      //max-width: 1250px;
      max-width: 1320px;
      //width: 70%;

      .content {
        display: flex;
        margin: 0 auto;
        justify-content: center;

        .left-bloc {
          width: 304px;

          @media(max-width: 1024px) {
            display: none;
          }

        }

        .right-bloc {
          flex: 1;
        }

      }

      .right-sidebar {
        display: flex;
        width: 270px;
        padding-left: 2.4rem;
        //position: absolute;
        //right: 3rem;

        .socials-block {
          width: 75px;

          &.fixed {
            position: fixed;
            top: 90px;
            right: 3rem;
          }

          .socials-inner {
            padding-top: 200px;

            ul {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;

              li {
                width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.7rem;
                opacity: 0.4;

                &:hover {
                  opacity: 1;
                  cursor: pointer;
                }

                button {
                  padding: 0;
                  background: transparent;
                }

              }
            }

          }

        }

      }

      .author-block {
        background: #F1F1F1;
        padding: 5%;
        //padding-left: 0;
        max-width: 900px;
        //margin: 0 auto;
        margin-top: 3rem;

        @media (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 6rem;
        }

        .inner {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transform: translateX(-52px);

          .informations {

            .name {
              font-weight: 800;
              font-size: 1.3rem;
              margin-bottom: 0.3rem;
              display: flex;
            }

            .metier {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                display: flex;
                color: #4E49FC;
                margin-right: 0.4rem;
              }
            }

            .email {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              i {
                display: flex;
                color: #4E49FC;
                margin-right: 0.4rem;
              }
            }

            div {
              display: flex;
              flex-direction: column;
              font-size: 0.9rem;
            }

          }

        }

        .avatar {
          border-radius: 100%;
          width: 104px;
          height: 104px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.3rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

        }

      }

      .sidebar-outer {
        width: 304px;

        @media (max-width: 425px) {
          width: 100%;
        }

        @media (max-width: 1024px){
          width: 100%;
        }

        @media (max-width: 1025px) and (min-height: 900px) {
          margin: 0 auto;
        }

      }

      .content-right {
        display: flex;
        flex-wrap: wrap;
      }

      .global-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 400px;
        max-width: 900px;
        //margin: 0 auto;
        //margin-bottom: 105px;
        min-height: 50vh;
        margin-bottom: 34px;
        margin-top: 2rem;

        a {
          text-decoration: underline !important;
          //&:hover {
          //  color: #f2d13e;
          //}
        }

        @media (max-width: 1440px) {
          max-width: 700px;
        }

        > div {
          position: relative;
          display: flex;
          flex-direction: column;

          &:after {
            @include wait(#f3f1f1);
          }
        }

      }

      .description {
        background: #F1F1F1;
        padding: 5%;
        position: relative;
        //max-width: 900px;
        max-width: 700px;
        position: relative;
        padding-top: 3%;
        padding-bottom: 3%;
        // margin: 0 auto;
        //margin-bottom: 3rem;
        //margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: auto;
        }

        @media (max-width: 1024px) {
          margin: 0 auto;
        }

        a {
          color: white;
          &:hover {
            text-decoration: underline !important;
          }
        }

        &:after {
          content: "";
          background: #f1f1f1;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(100%);
        }

        div {
          position: relative;
          &:after {
            @include wait(#eaeaea);
          }
        }

        p {
          width: 100%;
          //max-width: calc(100% - 272px);
          margin-bottom: 0;
          //font-style: italic;


          @media (max-width: 425px) {
            line-height: 1.6rem;
            font-size: 1.1rem;
          }

        }

      }

      h2 {
        //font-size: 1.66rem;
        //line-height: 2.47rem;
        margin-top: 1rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        color: #E2002B;
        text-transform: uppercase;
        line-height: 2.2rem;
      }

      h3 {
        font-size: 1.19rem;
        line-height: 1.52rem;
        font-weight: 600;
        // margin-top: 1.3rem;
        // margin-bottom: 1.3rem;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
      }

      p.style {
        border-left: solid 4px black;
        padding-left: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      .citation {
        text-align: center;
        margin: 0 auto;
        margin-top: 3rem;
        //margin-bottom: 3rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
      }

      .component-text {
        overflow: hidden;
        //font-size: 0.95rem;
        font-size: 1rem;
        letter-spacing: 0.15px;

        p {
          width: 100%;
        }

        em {
          width: 100%;
          display: flex;
        }

        img {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }

        ul {
          padding-left: 1.5rem !important;

          li {
            list-style-type: disc !important;
            margin-bottom: 0.4rem !important;
          }

        }

        &.style {
          border-left: solid 5px black;
          padding-left: 2rem;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;

          ul {
            padding-left: 0 !important;
          }

        }

        p {
          overflow-wrap: inherit;
          margin-bottom: 0;

          @media (max-width: 425px){
            font-size: 1.1rem;
            line-height: 1.6rem;
          }

        }
      }

      .citation q {
        font-size: 2.5rem;
        //line-height: 1.9rem;
        font-weight: 600;
        color: #E2002B;
        font-style: normal;
        text-transform: uppercase;
        line-height: 2.6rem;

        &:before {
          padding-right: 7px;
        }

        &:after {
          padding-left: 7px;
        }

      }

      span.author {
        font-size: 1rem;
        line-height: 1.9rem;
        margin-top: .9rem;
        color: #868686;
        text-decoration: none;
      }

    }

    header {
      background: #b0b0b0;
      color: white;

      .inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        z-index: 2;

        .inner-block {
          //height: 53%;
          height: 59%;
          flex-basis: 100%;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 4%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .article-title {
            width: 70%;
            font-weight: 800;
            font-size: 3.09rem;
            line-height: 3.80rem;
          }

          @keyframes topHeader {
            0% {
              opacity: 0;
              transform: translateY(10%);
            }
            100% {
              opacity: 1;
              transform: translateY(0%);
            }
          }

          .top-header-article {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            //animation: topHeader 0.4s ease;
          }

          .bottom-header-article {
            font-weight: 300;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .legende {
              position: relative;
              &:after {
                @include wait(#c6c5c5);
              }
            }

            .infos {
              font-size: 1.04rem;
              line-height: 1.47rem;
              position: relative;
              &:after {
                @include wait(#c6c5c5);
              }
            }

          }

          .infos {
            .time {
              margin-right: 47px;
              margin: 0;
            }
          }

          .chapitre {
            font-weight: 300;
            text-transform: uppercase;
            display: flex;
            position: relative;

            &:after {
              @include wait(#cfcfcf)
            }

            .number {
              font-weight: 600;
              font-size: 1.2rem;
              margin-right: 0.5em;
              background: #FF5F7D;
              padding: 0.2rem;
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

          }

        }

      }
    }
  }

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 36px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }

}

@media (min-width: 1500px){

  .her2020 {
    .article {

      .block-chapitre {
        .inner-chapitre {
          display: block;
        }
      }
    }
  }


}

.her2020 {
  @import "./components.scss";
  @import "./article-medias.scss";
}

@import "./content.scss";
