.her2020 {
  .add-to-homescreen-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 23px 0px #05050557;
    padding: 1rem;
    z-index: 10000;

    .title {
      cursor:pointer;
    }

    .cancelHomescreen img {
      width: 18px;
    }

  }
}
