@import "../../mixins.scss";

.her2020 {

  .content {
    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  .global-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 400px;
    max-width: 900px;
    //margin: 0 auto;
    //margin-bottom: 105px;
    min-height: 50vh;
    margin-bottom: 34px;

    @media (max-width: 1440px) {
      max-width: 700px;
    }

    @media (max-width: 425px){
      max-width: 100%;
      width: 100%;
      min-width: auto;
    }

    @media (min-height: 900px) and (max-width: 1025px){
      flex: 0 1 100%;
      min-width: 100%;
      width: 100%;
      max-width: 0;
      min-height: auto;

      & > div {
        padding-left: 9%;
        padding-right: 9%;
        box-sizing: border-box;
      }
    }

    > div {
      position: relative;
      display: flex;
      flex-direction: column;

      &:after {
        @include wait(#f3f1f1);
      }
    }

    .citation {
      text-align: center;
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 80%;
    }

    .component-text {
      overflow: hidden;
      //font-size: 0.95rem;
      font-size: 1rem;
      letter-spacing: 0.15px;

      &.style {
        border-left: solid 5px black;
        padding-left: 2rem;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }

      p {
        overflow-wrap: break-word;
        margin-bottom: 0;
      }
    }

    .citation q {
      font-size: 1.33rem;
      line-height: 1.9rem;
      font-weight: 600;
    }

    span.author {
      font-size: 1.14rem;
      line-height: 1.9rem;
      margin-top: 0.4rem;
      color: #000;
      text-decoration: underline;
    }

    h2 {
      font-size: 1.66rem;
      line-height: 2.47rem;
      margin-top: 1rem;
      font-weight: 700;
      margin-bottom: 1.1rem;
    }

    h3 {
      font-size: 1.19rem;
      line-height: 1.52rem;
      font-weight: 600;
      margin-top: 1.3rem;
      margin-bottom: 1.3rem;
    }

    p.style {
      border-left: solid 4px black;
      padding-left: 1.5rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .component-image {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        //width: 100%;
        //height: auto;
        width: 100%;
        //height: 400px;
        object-fit: cover;
        background: #f1f1f1;
        height: auto;
      }

      figcaption {
        margin-top: 0.8rem;
        color: #666666;
        font-size: 0.94rem;
      }

    }

  }

}
