//@media (max-width: 768px){

@media (min-height: 900px) and (max-width: 1025px){

  .her2020 {
    .lateralBarView {
      width: 72px;

      button.menu-toggle-btn svg {
        width: 72px;
        //transform: scale(0.75) translate(-8px, -4px) !important;
        //transform: scale(0.75) translate(-17px, -1px) !important;
      }

    }
  }

}

@media (max-width: 425px){

  .her2020 {

    .lateralBarView {
      width: 60px;
      right: 0;
      height: 60px !important;
      z-index: 10000000000;
      background: transparent !important;

      button.menu-toggle-btn {
        height: 60px;
        width: 60px;

        svg {
          width: 50px;
          height: 60px;
          transform: scale(1) translate(3px) !important;
        }

      }

      &.isSearching {
        button.menu-toggle-btn {
          g {
            fill: black;
          }
        }
      }

      button.menu-toggle-btn{
        background: transparent !important;
        border: 0 !important;

        &:hover {
          background: transparent;
        }
      }

      &.scrolled:not(.menuShow) {
        button.menu-toggle-btn {
          g {
            fill: black;
          }
        }
      }

      .menu {
        display: none
      }

      .numero-info {
        display: none;
      }

      .numero-socials {
        display: none;
      }

    }
  }

}
