//@media (max-width: 768px){

@media (max-height: 850px) and (min-width: 1024px){
  .her2020 {
    .article-couverture {
      > .inner {
        padding-bottom: 2.7rem !important;
      }

      h1, h2 {
        font-size: 4rem;
        line-height: 4rem;
      }
      .titles .subtitle {
        margin-bottom: 0.4rem;
      }
    }
  }
}

@media (min-height: 900px) and (max-width: 1025px){

  .her2020 {
    .couverture {

      .frame {
        height: 79vh !important;

        .title {
          padding-top: 5%;

          img {
            //max-width: initial;
            width: initial;
            max-width: 50%;
          }
        }

      }

      .chapitres {
        height: 22vh;

        > div {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        .chapitre {
          width: 75%;
          flex: 0 0 auto;

          a {
            justify-content: space-evenly;
            padding-bottom: 7%;
          }

          a .title {
            font-size: 1.24em;
            height: 2.48em;
            margin-bottom: 0rem;
          }

        }

      }

    }

    .article-couverture {

      > .inner {
        //padding-top: 9%;
        justify-content: center;
        //padding-bottom: 19%;
        padding: 7% 0 19% 7%;

        h2 {
          width: 91%;
        }

      }

    }

    //.start-btn {
    //  width: calc(100% / 2);
    //}
  }

}

@media (max-width: 425px) {

  .her2020 {
    .start-btn {
      bottom: 0;
      right: 0;
      width: auto;
    }

    .article-couverture {
      > .inner {
        position: absolute;
        display: flex;
        width: calc(100%);
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        color: white;
        z-index: 3;
        padding: 7%;
        padding-right: 7%;
        //margin-bottom: 16%;
        margin-bottom: 10%;
        padding: 1.7rem !important;
        bottom: 0;
      }
    }

    .article-view {

      .time, .status {
        font-size: 1.1rem !important;
      }
      .start-btn {
        visibility: hidden;
      }
    }

    .couverture {
      .chapitres {
        .chapitre {

          a .time {
            //position: absolute;
            //bottom: 10px;
          }

          a .title {
            font-size: 1.24em;
            height: auto;
            margin-bottom: 3rem;
            margin-top: 0.5rem;
          }

        }

      }

    }

    .couverture {

      .chapitres {
        height: 22vh;

        > div {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }

        .chapitre {
          width: 75%;
          flex: 0 0 auto;

          a {
            justify-content: space-evenly;
            padding-bottom: 7%;
          }

          a .title {
            font-size: 1.24em;
            height: 2.48em;
            margin-bottom: 0rem;
          }

        }

      }

      .frame {
        height: 76% !important;

        .article-couverture {

          h2 {
            width: 90%;
          }

          .inner {
            padding: 8%;
            padding-top: 26%;
          }

        }

        .title {
          padding-left: 4%;
          padding-top: 4%;
          z-index: 10000000000000;
          pointer-events: none;
        }

      }
    }
  }


}

@media(max-width: 375px) {

  .her2020 {
    .couverture {

      .article-couverture h2, .article-couverture h1 {
        font-size: 2.49rem;
        line-height: 3rem;
      }

      .chapitres {
        height: 28vh;

        .chapitre {

          a .title {
            font-size: 1.1em;
            height: auto;
            line-height: 1.4rem;
            margin-bottom: 0;
            margin-top: 0;
          }

          a .time {
            //position: absolute;
            //bottom: 10px;
          }

          a .chapitre-titre {
            font-size: 0.76rem;
            margin-bottom: 0.8rem;
            //height: 19px;
            height: 24px;
          }

        }

      }

    }
  }

}
