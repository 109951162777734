@font-face {
  font-family: 'icomoon-her2020-original';
  src:  url('icomoon-her2020-original.eot?5ns9tx');
  src:  url('icomoon-her2020-original.eot?5ns9tx#iefix') format('embedded-opentype'),
  url('icomoon-her2020-original.ttf?5ns9tx') format('truetype'),
  url('icomoon-her2020-original.woff?5ns9tx') format('woff'),
  url('icomoon-her2020-original.svg?5ns9tx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-her2020-original-"], [class*=" icon-her2020-original-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-her2020-original' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-her2020-original-arrow_down:before {
  content: "\e900";
}
.icon-her2020-original-arrow_left:before {
  content: "\e901";
}
.icon-her2020-original-arrow_right:before {
  content: "\e902";
}
.icon-her2020-original-arrow_up:before {
  content: "\e903";
}
.icon-her2020-original-avatar_author:before {
  content: "\e904";
}
.icon-her2020-original-burger:before {
  content: "\e905";
}
.icon-her2020-original-check:before {
  content: "\e906";
}
.icon-her2020-original-chevron_down:before {
  content: "\e907";
}
.icon-her2020-original-chevron_left:before {
  content: "\e908";
}
.icon-her2020-original-chevron_right:before {
  content: "\e909";
}
.icon-her2020-original-chevron_up:before {
  content: "\e90a";
}
.icon-her2020-original-close:before {
  content: "\e90b";
}
.icon-her2020-original-facebook:before {
  content: "\e90c";
}
.icon-her2020-original-instagram:before {
  content: "\e90d";
}
.icon-her2020-original-mail_author:before {
  content: "\e90e";
}
.icon-her2020-original-status_done:before {
  content: "\e90f";
}
.icon-her2020-original-status_undone:before {
  content: "\e910";
}
.icon-her2020-original-time:before {
  content: "\e911";
}
.icon-her2020-original-twitter:before {
  content: "\e912";
}
